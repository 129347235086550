@import "../../../Styles/index"

header
  width: 100%
  background-color: $black
  overflow: hidden
  height: auto

  section
    width: 100%
    max-width: 1200px
    margin: 0 auto
    display: flex
    flex-direction: column
    justify-content: start
    align-items: start
    gap: 40px
    @media screen and (max-width: 1024px)
      width: 90%

    @media screen and (max-width: 800px)
      gap: 0

    div
      margin-top: 0
      width: 100%

      button
        display: block
        margin: 30px 0 30px
        width: 100%
        max-width: 200px
        //@include font(30px, 40px, 500, $green)
        //text-decoration: none
        //white-space: nowrap


    img.img-header
      width: 100%
      max-width: 1200px
      margin: 0 auto

      @media screen and (max-width: 1200px)
        width: 95%

      @media screen and (max-width: 1024px)
        width: 100%

      @media screen and (max-width: 800px)
        height: 50vh
        object-fit: cover

  @media screen and (max-width: 800px)
    flex-wrap: wrap
  h2
    white-space: nowrap
  h3
    max-width: 500px
