@import "../../Styles/index"


article
  display: flex
  gap: 10px
  width: 100%
  max-width: 1200px
  margin: 0 auto
  @media screen and (max-width: 1024px)
    width: 90%

  @media screen and (max-width: 500px)
    flex-wrap: wrap

    section.card
      margin: 20px 0

section.card
  margin: 60px auto
  max-width: 1200px
  width: 100%
  @media screen and (max-width: 1024px)
    width: 90%

  @media screen and (max-width: 500px)
    flex-wrap: wrap

  &.inline
    display: inline-block

div.card
  padding: 60px 0
  margin: 0 auto
  max-width: 1200px
  width: 100%

  @media screen and (max-width: 1024px)
    width: 90%
    padding: 20px 0

p.dark
  @include font(20px, 28px, 500, $white)


ul.row-list
  display: flex
  flex-direction: row
  justify-content: space-between

  flex-wrap: nowrap
  gap: 24px

  @media screen and (max-width: 800px)
    flex-direction: column

  &.wrap
    flex-wrap: wrap

    li
      margin-bottom: 40px
      width: 100%

  li
    flex: 1 1
    border: none
    @media screen and (max-width: 1024px)
      max-width: 100%

ul.column-list
  display: flex
  flex-direction: column

  flex-wrap: nowrap
  gap: 24px
  width: 100%

  &.wrap
    flex-wrap: wrap

    li
      max-width: 100%
      width: 100%
      margin-bottom: 40px
      &.multiple
        max-width: 300px


  li
    border: none
    @media screen and (max-width: 1024px)
      max-width: 100%
    //&.multiple
      max-width: 300px


.img-list
  display: flex
  flex-direction: row
  justify-content: space-around
  gap: 24px
  flex-wrap: nowrap

  &.reverse
    flex-direction: row-reverse

  @media screen and (max-width: 1024px)
    flex-wrap: wrap

  img
    @media screen and (max-width: 1200px)
      width: 60vw
    @media screen and (max-width: 1024px)
      width: 90vw

  ul
    flex: 1 1 auto
    display: flex
    flex-direction: column
    width: 100%

    li
      @media screen and (max-width: 800px)
        max-width: 100%
        width: 100%

      h3
        @include font(30px, 40px, 400, $white)

.flex
  display: flex
  justify-content: space-between
  flex-wrap: nowrap
  gap: 24px

  h2
    margin-bottom: 0

  @media screen and (max-width: 600px)
    flex-wrap: wrap
  &.prod
    justify-content: start

  p
    color: $white
    max-width: 384px
    align-self: flex-end

  h3
    @include font(24px, 28px, 500, $white)
