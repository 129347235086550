$black: #2C2C2C
$white: #F0F0F0
$green: #16CD8B
$blue: #16A1CD

@mixin font($size, $lh, $fw, $color)
  font-family: 'Raleway', sans-serif
  font-style: normal
  font-size: $size
  line-height: $lh
  font-weight: $fw
  color: $color





*
  margin: 0
  padding: 0
  box-sizing: border-box
  z-index: 2

  &::-webkit-scrollbar
    width: 15px
    height: 15px

  &::-webkit-scrollbar-thumb
    background-color: #818181
    border-radius: 5px

  &::-webkit-scrollbar-track
    background-color: $black
    //background-color: #ffffff

html
  height: 100%

  body
    height: 100%
    background-color: $black
    main
      background-color: $black
      display: flex
      flex-direction: column
    @media screen and (max-width: 3000px)
      height: 100%
h1
  @include font(80px, 80px, 600, $white)

  @media screen and (max-width: 850px)
    @include font(80px, 80px, 600, $white)
    padding: 0 0 40px 0

  @media screen and (max-width: 800px)
    @include font(38px, 38px, 600, $white)
    padding: 0 0 40px 0

h1.green
  @include font(80px, 80px, 600, $green)
  @media screen and (max-width: 800px)
    @include font(38px, 38px, 600, $green)
h2
  @include font(50px, 50px, 600, $green)
  margin-bottom: 80px
  @media screen and (max-width: 800px)
    @include font(30px, 34px, 600, $green)
    margin-bottom: 40px

h2.white
  @include font(50px, 50px, 600, $white)
  margin-bottom: 40px
  @media screen and (max-width: 800px)
    @include font(30px, 34px, 600, $white)
    margin-bottom: 40px
  @media screen and (max-width: 570px)
    width: 142px

h3
  @include font(28px, 34px, 600, $white)
  margin-bottom: 20px

  @media screen and (max-width: 800px)
    @include font(24px, 28px, 600, $white)
    margin-bottom: 10px

h3.white
  @include font(28px, 34px, 600, $white)
  margin-bottom: 20px

  @media screen and (max-width: 800px)
    @include font(24px, 28px, 600, $white)
    max-width: 344px
    margin-bottom: 10px

h3.blue
  @include font(28px, 34px, 600, $blue)
  margin-bottom: 20px

h4
  @include font(28px, 33px, 600, $blue)
  margin-bottom: 40px

h5
  max-width: 550px
  width: 100%
  @include font(30px, 40px, 600, #ABABAB)
  @media screen and (max-width: 800px)
    @include font(20px, 24px, 600, #ABABAB)
p
  @include font(20px, 28px, 500, #ABABAB)


ul
  list-style: none

nav
  @include font(28px, 33px, 800, $blue)
  position: relative
  width: 100%
  max-width: 1200px
  height: 80px
  margin: 0 auto

  display: flex
  justify-content: start
  align-items: center
  gap: 50px

  @media screen and (max-width: 1024px)
    width: 90%

  @media screen and (max-width: 320px)
    height: auto
    display: block
    margin: 10px auto

  button
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    padding: 11px 43px
    background: #5F5F5F
    border-radius: 10px
    border: none
    outline: none
    @include font(16px, 20px, 500, $white)
    margin: 10px 0