@import "../../../Styles/index"

address
  position: relative
  a
    @include font(100px, 100px, 700, $black)
    text-decoration: none
    border-bottom: 5px solid $black

    @media screen and (max-width: 800px)
      @include font(30px, 34px, 700, $black)

  div
    width: 180px
    height: 180px
    border-radius: 50%
    position: absolute
    top: -95px
    left: 875px

    @media screen and (max-width: 1080px)
      left: calc(100% - 180px)
      top: -170px

    @media screen and (max-width: 500px)
      width: 120px
      height: 120px

    @media screen and (max-width: 450px)
      left: 55%

  span.circle
    background-color: $white
    background-image: url('../../../../public/btn-arrow-white.svg')
    background-position: center center
    display: block
    width: 180px
    height: 180px
    border-radius: 50%
    @media screen and (max-width: 500px)
      width: 120px
      height: 120px

  span.circle-black
    content: ''
    background-color: $black
    background-position: center center
    display: block
    width: 180px
    height: 180px
    border-radius: 50%
    position: absolute
    top: 0
    background-image: url('../../../../public/btn-arrow-black.svg')
    transition: all .3s ease-in

    @media screen and (max-width: 500px)
      width: 120px
      height: 120px

    &.min
      clip-path: circle(0% at 50% 50%)
    &.max
      clip-path: circle(100% at 50% 50%)

footer
  width: 100%
  display: flex
  flex-direction: column
  justify-content: flex-end
  align-items: center
  padding: 60px 0 0
  background: $green
  @media screen and (min-width: 2000px)
    height: 56%
  @media screen and (max-width: 1080px)
    padding: 120px 0 0
  div.card
    padding: 0
    @media screen and (max-width: 1060px)
      width: 90%

  .rolling-text
    overflow: hidden
    margin: 110px 0 40px
    height: 170px
    width: 100%
    position: relative

    @media screen and (max-width: 500px)
      height: 70px
      margin: 40px 0 20px

    strong
      @include font(160px, 160px, 700, $white)
      height: 160px
      opacity: 0.2
      position: absolute
      top: 0
      left: 0
      animation: roll-text 4s linear infinite
      @media screen and (max-width: 500px)
        height: 50px
        @include font(60px, 60px, 700, $white)

  @keyframes roll-text
    0%
      left: -100%
    100%
      left: 100%

  ul
    display: flex
    flex-direction: row
    justify-content: space-around
    align-items: baseline
    padding: 20px 10px
    gap: 10px
    margin: 0 auto
    width: 100%
    border-top: 1px solid #F0F0F0

    @media screen and (max-width: 768px)
      flex-wrap: wrap

    @media screen and (max-width: 550px)
      justify-content: flex-start
      flex-direction: column

    li
      border: none
      color: $white
      display: flex
      justify-content: center
      @include font(24px, 28px, 600, $white)

      @media screen and (max-width: 900px)
        @include font(18px, 20px, 600, $white)
        max-width: 100%

      h3
        max-width: 371px
        text-align: center
        @include font(24px, 28px, 600, $white)

        @media screen and (max-width: 900px)
          @include font(18px, 20px, 600, $white)


      p
        @include font(24px, 28px, 600, $white)
        text-decoration: none
        border-bottom: 2px solid $white
        white-space: nowrap
        cursor: pointer
        @media screen and (max-width: 900px)
          @include font(18px, 20px, 600, $white)
          text-align: center

      a
        @include font(24px, 28px, 600, $white)
        text-decoration: none
        border-bottom: 2px solid $white
        white-space: nowrap
        @media screen and (max-width: 900px)
          @include font(18px, 20px, 600, $white)
          text-align: center



dialog
  background-color: #00000055
  border: none
  width: 100%
  height: 100%
  display: flex
  position: fixed
  bottom: 0

  article.container
    display: flex
    flex-direction: column
    border-radius: 20px 20px 0 0
    background-color: #454545
    max-width: 1280px
    width: 100%
    margin: 0 auto
    padding: 40px 40px 10px 40px
    align-self: end
    flex-wrap: nowrap

    @media screen and (max-height: 900px)
      overflow-y: auto
      height: 80vh

  .title
    display: flex
    flex-direction: row
    justify-content: space-between
    margin-bottom: 60px
    @media screen and (max-width: 800px)
      margin-bottom: 20px

    h2
      margin: 0
      @include font(60px, 60px, 700, $white)
      @media screen and (max-width: 800px)
        @include font(30px, 34px, 700, $white)



    span
      width: 30px
      height: 30px
      position: relative

      &:before
        content: ''
        display: block
        width: 28px
        height: 2px
        background-color: $white
        border-radius: 2px
        transform: rotate(-45deg)
        position: absolute
        top: 15px
        left: 0

      &:after
        content: ''
        display: block
        width: 28px
        height: 2px
        background-color: $white
        border-radius: 2px
        transform: rotate(45deg)
        position: absolute
        top: 15px
        left: 0


  div.main-aside
    display: flex
    flex-wrap: nowrap
    justify-content: space-between
    width: 100%
    @media screen and (max-width: 768px)
      flex-wrap: wrap

  section
    max-width: 588px
    width: 100%
    padding: 0 0 60px 0
    @media screen and (max-width: 800px)
      padding: 0 0 30px 0
    .card
      padding: 0
      margin: 0

      ul.row-list
        @media screen and (max-width: 1060px)
          flex-direction: column

      h2
        @include font(30px, 40px, 700, $green)
        margin-bottom: 40px
        @media screen and (max-width: 800px)
          @include font(25px, 30px, 700, $green)
          margin-bottom: 20px

      h3
        @include font(24px, 30px, 600, $white)
        margin-bottom: 20px
        @media screen and (max-width: 800px)
          @include font(20px, 26px, 700, $white)
          margin-bottom: 10px

      p
        @include font(18px, 24px, 500, $white)

i
  color: $blue
  font-style: normal